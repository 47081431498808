<template>
  <v-card>
    <v-card-title class="">
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-treeview
        :items="permissoes"
        :search="search"
        :open.sync="open"
        item-text="descricao"
        item-key="id"
        item-children="children"
        hoverable
        activatable
        transition
        dense
        return-object
        class="data__row-click"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon>
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div @click="goToPermissao(item)" class="text-body-2">
            {{ item.descricao }}
          </div>
        </template>
        <template v-slot:append="{ item }">
          <div class="d-flex justify-end align-center">
            <div class="d-none d-md-block text-caption">
              {{ item.componente }}
            </div>
          </div>
        </template>
      </v-treeview>
    </v-card-text>

    <Permissao
      v-if="dialogPermissao"
      :dialogPermissao.sync="dialogPermissao"
      :permissao_id="permissao_id"
    />
  </v-card>
</template>

<script>
import { getPermissoes } from "@/api/admin/permissoes.js";
// import { mapGetters } from "vuex";

export default {
  name: "ListaPermissoes",

  components: {
    Permissao: () => import("../Permissao.vue"),
  },

  data() {
    return {
      search: "",
      loading: false,
      permissoes: [],
      dialogPermissao: false,
      open: [],
      permissao_id: null,
    };
  },

  methods: {
    goToPermissao(item) {
      this.dialogPermissao = true;
      this.permissao_id = item.id;
    },

    async fetchPermissoes() {
      try {
        const response = await getPermissoes();
        this.permissoes = response;
        this.permissoes.forEach((permissao) => {
          this.open.push(permissao.id);
          permissao.children.forEach((child) => {
            this.open.push(child.id);
          });
        });
      } catch (error) {
        return console.log(error);
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.fetchPermissoes();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss"></style>

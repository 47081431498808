var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-divider'),_c('v-card-text',[_c('v-treeview',{staticClass:"data__row-click",attrs:{"items":_vm.permissoes,"search":_vm.search,"open":_vm.open,"item-text":"descricao","item-key":"id","item-children":"children","hoverable":"","activatable":"","transition":"","dense":"","return-object":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2",on:{"click":function($event){return _vm.goToPermissao(item)}}},[_vm._v(" "+_vm._s(item.descricao)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"d-none d-md-block text-caption"},[_vm._v(" "+_vm._s(item.componente)+" ")])])]}}])})],1),(_vm.dialogPermissao)?_c('Permissao',{attrs:{"dialogPermissao":_vm.dialogPermissao,"permissao_id":_vm.permissao_id},on:{"update:dialogPermissao":function($event){_vm.dialogPermissao=$event},"update:dialog-permissao":function($event){_vm.dialogPermissao=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }